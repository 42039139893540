/***********************************************
* LandingPage.js
* 
* Show a landingpage
***********************************************/
/* Layout files */
import '../assets/style/ExamplePage.css';

/* Global imports */
import React   from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Markdown from 'markdown-to-jsx'

/* Allow this component to navigate to different pages */
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (<Component {...props} router={{ location, navigate, params }} />);
    }
  
    return ComponentWithRouterProp;
}

class ExamplePage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { markdown: '' };

        fetch(window.location.origin + '/docs/ExamplePage/' + props.language.active + '.md').then(res => res.text()).then(text => this.setState({ markdown: text }));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.language !== this.props.language) {
            fetch(window.location.origin + '/docs/ExamplePage/' + this.props.language.active + '.md').then(res => res.text()).then(text => this.setState({ markdown: text }));
        }
    }

    render() {
        const navigate = this.props.router.navigate;
        const { markdown } = this.state;

        return (
            <div className="fullExamplePage">
                <div className="loginExampleDiv">
                    <select name="language" id="language" className="languageSelect" value={this.props.language.active} onChange={(e) => this.props.language.set(e.target.value)}>
                        {this.props.language.list.map((lang) => <option key={lang} value={lang}>{lang}</option>)}
                    </select>
                    <div onClick={() => navigate("/projects")} className="button bigButton">Login</div>
                </div>
                <div className='contentPage'>
                    <Markdown>{markdown}</Markdown>
                </div>
            </div>
        );
    }
}

export default withRouter(ExamplePage);