/***********************************************
* Project.js
* 
* Handles the project view of the application
***********************************************/
/* Layout files */
import '../assets/style/Project.css';

/* Global imports */
import React, { useEffect, useState } from 'react';
import L                              from 'leaflet';
import { 
    MapContainer,
    TileLayer,
    Marker,
    Polygon,
    useMapEvents,
    Tooltip
}                                     from 'react-leaflet';
import proj4                          from 'proj4';
import _                              from 'lodash';

import icon         from 'leaflet/dist/images/marker-icon.png';
import iconShadow   from 'leaflet/dist/images/marker-shadow.png';
import iconSelected from '../assets/images/selected-marker-icon.png';
import iconCenter   from '../assets/images/center-marker-icon.png';

import triangleImg  from "../assets/images/triangle.png";
import MeasureMarkers from "../assets/images/MeasureMarkers.gif";
import Selection    from "../assets/images/Selection.gif";

/* Local imports */
import API from './API';
import { CoordinatesPopup, InfoSlide, ConfirmPopup, InfoPopup, HelpPopup } from './Popup';

/* Icons to use on the map as markers */
let DefaultIcon  = L.icon({ iconUrl: icon,         shadowUrl: iconShadow, iconSize: [25,41], iconAnchor: [12,41] });
let CenterIcon  =  L.icon({ iconUrl: iconCenter,   shadowUrl: iconShadow, iconSize: [25,41], iconAnchor: [12,41] });
let SelectedIcon = L.icon({ iconUrl: iconSelected, shadowUrl: iconShadow, iconSize: [25,41], iconAnchor: [12,41] });

L.Marker.prototype.options.icon = DefaultIcon;  // Set the default Icon of a marker

/* Calculate the Boundingbox coordinates using the center, width, height and rotation */
function GetBBox(w, h, rot, center) {
    const SizeToCoords = (coord, w, h) => {
        var offset_lat = h / 111111;
        var offset_lon = w / (111111 * Math.cos((coord['lat'] * Math.PI) / 180));
        return [{lat: coord['lat'] + offset_lat, lng: coord['lng'] - offset_lon}, {lat: coord['lat'] - offset_lat, lng: coord['lng'] + offset_lon}];
    }
    const toRadians = (deg) => { return (deg * Math.PI) / 180.0; }
    var w2 = w / 2;
    var h2 = h / 2;
    var cosR = Math.cos(toRadians(rot));
    var sinR = Math.sin(toRadians(rot));

    var offsetX1 = (w2 * cosR) + (h2 * sinR);
    var offsetX2 = (w2 * cosR) - (h2 * sinR);
    var offsetY1 = (h2 * cosR) - (w2 * sinR);
    var offsetY2 = (h2 * cosR) + (w2 * sinR);

    var coord12 = SizeToCoords(center, offsetX1, offsetY1);
    var coord34 = SizeToCoords(center, offsetX2, -offsetY2);

    return [coord12[0], coord34[0], coord12[1], coord34[1]];
}

/* Calculate the Boundingbox corner offset coordinates */
function GetCornerCoords(coords) {
    const getPoint = (p1, p2) => { return { 'lat': 0.9 * p1['lat'] + 0.1 * p2['lat'], 'lng': 0.9 * p1['lng'] + 0.1 * p2['lng'] }; };

    var pts = [];
    coords.forEach((coord, idx, list) => {pts.push(getPoint(list[0], coord));})
    return pts;
}

/* Create a boundingbox on a Leaflet MapContainer */
function BoundingBox(props) {
    const [position, setPosition] = useState(props.startPosition);  // The center position
    const [tmpPos,   setTmpPos  ] = useState(props.startPosition);  // The center position before move is accepted
    const [width,    setWidth   ] = useState(props.w);              // The width
    const [height,   setHeight  ] = useState(props.d);              // The height
    const [rotation, setRotation] = useState(props.r);              // The rotation
    const [confirmOpen, setConfirmOpen] = useState(false);          // The popup state to move the center
    const [bBox,     setBBox    ] = useState(GetBBox(width, height, rotation, position));   // The corner coordinates of the boundingbox
    const [bBoxCorner, setBBoxCorner] = useState(GetCornerCoords(bBox));    // The left upper corner coordinates
    const bbColor = { color: 'blue' }                               // The color of the boundingbox on the map

    /* Handle a click on the map */
    const map = useMapEvents({ click(e) {
        var position = {};
        position['lat'] = Math.round(e.latlng['lat'] * 1000000) / 1000000;  // Get the Latitude of the click
        position['lng'] = Math.round(e.latlng['lng'] * 1000000) / 1000000;  // Get the Longitude of the click
        setTmpPos(position);        // Save the temporary position
        setConfirmOpen(true);       // Open the confirm dialog
    }, });

    /* If width, height, rotation or position changed, update the boundingbox */
    useEffect(() => { 
        var boundBox = GetBBox(width, height, rotation, position);
        setBBox(boundBox);
        setBBoxCorner(GetCornerCoords(boundBox)); 
    }, [width, height, rotation, position]);

    /* If one of the props changes, propagate it to the state */
    if(props.w !== width)    { setWidth(props.w);     }
    if(props.d !== height)   { setHeight(props.d);    }
    if(props.r !== rotation) { setRotation(props.r);  }
    if(props.startPosition !== position) {
        setPosition(props.startPosition);
        map.flyTo(props.startPosition, map.getZoom());
    }

    /* Add the boundingbox to the map */
    return (<>
        <Marker position={position} icon={CenterIcon}/>
        <Polygon pathOptions={bbColor} positions={[bBox, bBoxCorner]} />
        <ConfirmPopup 
            t={props.t}
            open={confirmOpen}
            title={props.t("moveboundingbox")} 
            message={props.t("moveboundingboxconfirm")} 
            onAccept={() => {
                setPosition(tmpPos);
                props.onMove(tmpPos);
                setConfirmOpen(false);
            }}
            onDecline={() => { setConfirmOpen(false); }}
        />
    </>);
}

/* Detect Zoom changes of the map */
function ZoomChange(props) {
    const [zoomLevel, setZoomLevel] = useState(0); // initial zoom level provided for MapContainer
    const { onZoom } = props;

    /* Handle zoom change */
    const mapEvents = useMapEvents({ zoomend: () => { setZoomLevel(mapEvents.getZoom()); }, });
    useEffect(() => { if(zoomLevel !== 0) onZoom(zoomLevel); }, [zoomLevel]);   // eslint-disable-line react-hooks/exhaustive-deps

    return null
}

/* Selection box for the different types of  */
function MarkerTypeSelect(props) {
    var options = []
    Object.keys(PointDefinitions).forEach((key) => { options.push(<option key={key} value={PointDefinitions[key]}>{key}</option>) });
    return (<select name="markerOptions" onChange={(e) => {props.onChange(e)}} value={props.value}>{options}</select>);
}

/* Options for distance markers */
function DistanceOptions(props) {
    var ret = [];
    ret.push(<tr key="title">
        <th colSpan='5'>{props.t("markersgcp")}</th>
    </tr>);
    ret.push(<tr key="markerOptions"><td colSpan='5' className='center'>
        <MarkerTypeSelect onChange={(e) => {props.onPointDefChanged(e)}} value={props.pointDefinition} />
    </td></tr>);
    if(props.distances.length !== 0) {
        ret.push(<tr key="header"><td className='center'>{props.t("marker1")}</td><td className='center'>{props.t("marker2")}</td><td className='center'>{props.t("distance")}</td></tr>);
    }
    props.distances.forEach((distance, i) => {
        ret.push(
        <tr key={"dist_" + i}>
            <td><select name="markerPoint"   value={distance.point1}   onChange={(e) => props.onChange(i, parseInt(e.target.value), undefined, undefined)}>{ props.points }</select></td>
            <td><select name="markerPoint_2" value={distance.point2}   onChange={(e) => props.onChange(i, undefined, parseInt(e.target.value), undefined)}>{ props.points }</select></td>
            <td><input type="number"         value={distance.distance} onChange={(e) => props.onChange(i, undefined, undefined, parseFloat(e.target.value))} className='shortInput'/></td>
            <td>m</td>
            <td><button className="smallButton" onClick={() => props.onDelete(i)}>&times;</button></td> 
        </tr>);
    });
    ret.push(<tr key="add"><td colSpan='5' className='center'><button className="smallButton" onClick={() => props.onCreate()}>+</button></td></tr>);
    return ret;
}

/* Options for coordinate markers */
function CoordinateOptions(props) {
    const [epsg, setEpsg] = useState(props.epsg);

    if(props.epsg !== epsg) { setEpsg(props.epsg); }

    var ret = [];
    ret.push(<tr key="info">
        <th colSpan='7'>{props.t("markersgcp")}</th>
        <td>
            <InfoSlide key="infoSlide" text={props.t("coordinatesin") + " " + epsg.name} />
        </td></tr>);
    ret.push(<tr key="select"><td colSpan='5' className='center'>
                <MarkerTypeSelect onChange={(e) => {props.onPointDefChanged(e)}} value={props.pointDefinition} />
            </td><td className='center' colSpan='3'><button className='smallButton' onClick={() => props.onOpenCsv()}>{props.t("loadfromcsv")}</button></td></tr>);
    if(props.coordinates.length !== 0) {
        ret.push(<tr key="header"><td className='center'>{props.t("marker")}</td><td colSpan='2' className='center'>{epsg.lng}</td><td colSpan='2' className='center'>{epsg.lat}</td><td colSpan='2' className='center'>{epsg.alt}</td></tr>);
    }
    props.coordinates.forEach((coord, i) => {
        ret.push(
        <tr key={"coord_" + i}>
            <td><select name="markerPoint" value={coord.point} onChange={(e) => props.onChange(i, parseInt(e.target.value), undefined, undefined)} onFocus={() => props.onFocusChanged(i)}>{ props.points }</select></td>
            <td className="alignRight"><ThousandsInput key={"x" + i} type="number" onChange={(val) => props.onChange(i, undefined, [val, coord.lat], undefined)} value={coord.lng} className='shortInput' onFocus={() => props.onFocusChanged(i)}/></td>
            <td>{epsg.lngUnit}</td>
            <td className="alignRight"><ThousandsInput key={"y" + i} type="number" onChange={(val) => props.onChange(i, undefined, [coord.lng, val], undefined)} value={coord.lat} className='shortInput' onFocus={() => props.onFocusChanged(i)}/></td>
            <td>{epsg.latUnit}</td>
            <td className="alignRight"><ThousandsInput key={"z" + i} type="number" onChange={(val) => props.onChange(i, undefined, undefined, val)} value={coord.alt} className='shortInput' onFocus={() => props.onFocusChanged(i)}/></td>
            <td>{epsg.altUnit}</td>
            <td><button className="smallButton" onClick={() => props.onDelete(i)}>&times;</button></td>
        </tr>);
    });
    ret.push(
        <CoordinatesPopup 
            t={props.t}
            key='coordsPopup' 
            open={props.popupOpen} 
            markers={props.points} 
            onLoadCoords={props.onLoadCoords}
            onClose={props.onClose}
        />);
    ret.push(<tr key='add'><td colSpan='8' className='center'><button className="smallButton" onClick={() => props.onCreate()}>+</button></td></tr>);
    return ret;
}

/* Options for triangle markers */
function TriangleOptions(props) {
    var ret = [];
    ret.push(<tr key="title">
        <th colSpan='4'>{props.t("markersgcp")}</th>
        <td>
            <InfoSlide key="infoSlide" text={props.t("triangleinfo")} />
        </td>
    </tr>);
    ret.push(<tr key="markerOptions"><td colSpan='5' className='center'>
        <MarkerTypeSelect onChange={(e) => {props.onPointDefChanged(e)}} value={props.pointDefinition} />
    </td></tr>);
    ret.push(
    <tr key="add">
        <td colSpan='2'>
            <table><tbody>
                <tr><th></th><th></th><th>{props.t("point")}</th><th>{props.t("altitude")}</th></tr>
                <tr>
                    <td></td>
                    <td>A</td>
                    <td><select name="markerPoint" onChange={(e) => props.onChange({...props.triangle, point1: e.target.value})} value={(props.triangle != null) ? props.triangle.point1 : 0}>{props.points}</select></td>
                    <td><input type="number" onChange={(e) => props.onChange({...props.triangle, alt1: e.target.value})} value={(props.triangle != null) ? props.triangle.alt1 : ""} className='shortInput'/></td>
                    <td>m</td>
                </tr>
                <tr>
                    <td></td>
                    <td>B</td>
                    <td><select name="markerPoint" onChange={(e) => props.onChange({...props.triangle, point2: e.target.value})} value={(props.triangle != null) ? props.triangle.point2 : 0}>{props.points}</select></td>
                    <td><input type="number" onChange={(e) => props.onChange({...props.triangle, alt2: e.target.value})} value={(props.triangle != null) ? props.triangle.alt2 : ""} className='shortInput'/></td>
                    <td>m</td>
                </tr>
                <tr>
                    <td></td>
                    <td>C</td>
                    <td><select name="markerPoint" onChange={(e) => props.onChange({...props.triangle, point3: e.target.value})} value={(props.triangle != null) ? props.triangle.point3 : 0}>{props.points}</select></td>
                    <td><input type="number" onChange={(e) => props.onChange({...props.triangle, alt3: e.target.value})} value={(props.triangle != null) ? props.triangle.alt3 : ""} className='shortInput'/></td>
                    <td>m</td>
                </tr>
                <tr><th></th><th></th><th>{props.t("distance")}</th><th></th></tr>
                <tr>
                    <td></td>
                    <td>x</td>
                    <td><input type="number" onChange={(e) => props.onChange({...props.triangle, dist23: e.target.value})} value={(props.triangle != null) ? props.triangle.dist23 : ""} className='shortInput'/></td>
                    <td>m</td>
                </tr>
                <tr>
                    <td></td>
                    <td>y</td>
                    <td><input type="number" onChange={(e) => props.onChange({...props.triangle, dist31: e.target.value})} value={(props.triangle != null) ? props.triangle.dist31 : ""} className='shortInput'/></td>
                    <td>m</td>
                </tr>
                <tr>
                    <td></td>
                    <td>z</td>
                    <td><input type="number" onChange={(e) => props.onChange({...props.triangle, dist12: e.target.value})} value={(props.triangle != null) ? props.triangle.dist12 : ""} className='shortInput'/></td>
                    <td>m</td>
                </tr>
            </tbody></table>
        </td>
        <td colSpan='3'>
            <img src={triangleImg} className="triangleImg" alt=""/>
        </td>
    </tr>);
    return ret;
}

/* Input type which adds ' to thousands */
function ThousandsInput(props) {
    const addSeparator = (num) => {
        var splitStr = num.toString().split(".");
        var decimal = (splitStr.length > 1) ? "." + splitStr[1] : "";
        return splitStr[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'") + decimal
    };
    const removeNonNumeric = num => num.toString().replace(',', '.').replace(/[^0-9.]/g, "");

    const [textValue, setTextValue] = useState(addSeparator(parseFloat(props.value)));

    if(parseFloat(props.value) !== parseFloat(removeNonNumeric(textValue))) {
        setTextValue(addSeparator(props.value));
    }

    const handleChange = event => {
        var val = removeNonNumeric(event.target.value);
        setTextValue(addSeparator(val));
        props.onChange(parseFloat(val) || 0.00);
    }

    return (<input disabled={props.disabled} type="text" value={textValue} onInput={handleChange} className={props.className} onFocus={props.onFocus}/>);
};

/* Marker types */
const PointDefinitions = {Distance: 0, Coordinates: 1, Triangle: 2}

/* Project Component */
class Project extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            id: this.props.projectId,
            imageCount:   -1,
            imagePosition: -1,
            bbCalculated: false,
            bb: {
                id:         0,
                width:   20.0,
                depth:   20.0,
                height: 100.0,
                rotation: 0.0,
                lat: 51.249620485604794,
                long: 4.498727266064874,
            },
            generate: {
                pc:     true,
                mesh:   true,
                acMesh: true,
                ortho:  true,
            },
            pcPoints: 1000000,
            meshTriangles: 30000,
            pointDefinition: PointDefinitions.Distance,
            distances:  [],
            coordinates:[],
            triangle: {},
            coordPopupOpen: false,
            selectedMarker: -1,
            baseModel: false,
            confirmRun: false,
            infoRun: false,
            epsg: 31370,
            epsgInfo: {name:"Lambert72", epsg:"31370", lat:"Y", lng:"X", alt:"Z", latUnit:"m", lngUnit:"m", altUnit:"m"},
            zoomLevel: 13,
            runWhenUpload: false,
        };

        /* Define conversion */
        proj4.defs("EPSG:31370","+proj=lcc +lat_1=51.16666723333334 +lat_2=49.83333389999999 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-99.1,53.3,-112.5,0.419,-0.83,1.885,-1.0 +units=m +no_defs");
        var wgsLambert = proj4("EPSG:31370");
        this.toLambert = wgsLambert.forward;
        this.fromLambert = wgsLambert.inverse;

        /* Bind all functions to 'this' */
        this.StartPollBackend       = this.StartPollBackend.bind(this);
        this.StopPollBackend        = this.StopPollBackend.bind(this);
        this.sendDelayed            = this.sendDelayed.bind(this);

        this.handleMapClick         = this.handleMapClick.bind(this);
        this.handleProjectReply     = this.handleProjectReply.bind(this);
        this.handleBbChange         = this.handleBbChange.bind(this);
        this.handleProjectChange    = this.handleProjectChange.bind(this);
        this.handleZoomStateChanged = this.handleZoomStateChanged.bind(this);
        this.handleBbStateChanged   = this.handleBbStateChanged.bind(this);
        this.handleStateChanged     = this.handleStateChanged.bind(this);
        this.handleAddProjectToQueue= this.handleAddProjectToQueue.bind(this);
        this.sendChangeProject      = this.sendChangeProject.bind(this);
        this.sendChangeBoundingBox  = this.sendChangeBoundingBox.bind(this);
        this.sendChangeZoom         = this.sendChangeZoom.bind(this);

        this.handleDistsCoordsChanged   = this.handleDistsCoordsChanged.bind(this);
        this.sendChangeDistsCoords      = this.sendChangeDistsCoords.bind(this);
        this.handleDistancesChanged     = this.handleDistancesChanged.bind(this);
        this.handleDistanceDeleted      = this.handleDistanceDeleted.bind(this);
        this.sendChangeDistance         = this.sendChangeDistance.bind(this);
        this.handleCreateDistanceDone   = this.handleCreateDistanceDone.bind(this);
        this.handleCoordinatesChanged   = this.handleCoordinatesChanged.bind(this);
        this.handleCoordinateDeleted    = this.handleCoordinateDeleted.bind(this);
        this.sendChangeCoordinate       = this.sendChangeCoordinate.bind(this);
        this.handleCreateCoordinateDone = this.handleCreateCoordinateDone.bind(this);
        this.handleTriangleChanged      = this.handleTriangleChanged.bind(this);
        this.sendChangeTriangle         = this.sendChangeTriangle.bind(this);

        this.coordDist                  = this.coordDist.bind(this);
        this.loadCoords                 = this.loadCoords.bind(this);   

        /* Send timeout variables */
        this.sendBbTimeout   = {interval: null, time: 2000, callback: this.sendChangeBoundingBox};
        this.sendTimeout     = {interval: null, time: 2000, callback: this.sendChangeProject};
        this.sendZoomTimeout = {interval: null, time: 2000, callback: this.sendChangeZoom};
        this.sendDistsCoords = {interval: null, time: 2000, callback: this.sendChangeDistsCoords};
        this.sendDistance    = {interval: null, time: 2000, callback: this.sendChangeDistance};
        this.sendCoordinate  = {interval: null, time: 2000, callback: this.sendChangeCoordinate};
        this.sendTriangle    = {interval: null, time: 2000, callback: this.sendChangeTriangle};
    }

    /* On Prop update */
    componentDidUpdate(prevProps) {
        if(prevProps.projectId !== this.props.projectId) {  // Different project selected?
            if(this.props.projectId !== 0) {                // If project ID is not 0, get all project info
                this.StopPollBackend();
                API.GetProject(this.props.projectId, this.handleProjectReply);
                API.GetOutputFiles(this.props.projectId, this.handleOutputFileList);
                this.StartPollBackend();
            } else {                                        // Else stop polling project info
                this.StopPollBackend();
            }
            this.setState({id: this.props.projectId})       // Save the new project ID
        }

        if(prevProps.uploadDone !== this.props.uploadDone) {        // All images are uploaded?
            if(this.props.uploadDone && this.state.runWhenUpload) { // If project is started, add to queue
                API.AddProjectToRunList(this.state.id); 
                this.setState({runWhenUpload: false});
            }
        }
    }

    /* Poll the project state every 10 seconds */
    StartPollBackend() {
        clearInterval(this.apiTimer);
        this.apiTimer = setInterval(() => {
            API.GetProject(this.state.id, this.handleProjectReply);
        }, 10000);
    }
    /* Stop polling the project state */
    StopPollBackend(){ clearInterval(this.apiTimer); }

    /* Send changes to the backend when they haven't changed for n seconds */
    sendDelayed(callInfo) {
        clearTimeout(callInfo.interval);
        callInfo.interval = setTimeout(callInfo.callback, callInfo.time);
    }

    /* Save the project state to the state */
    handleProjectReply(jsonReply) {
        var coords = jsonReply['coordinates'];
        var imgCnt = jsonReply['imageCount'];
        var imgPos = jsonReply['imagePosition'];
        var bbCalc = jsonReply['bb_calculated'];
        var triangle = jsonReply['triangle'];

        var generate = {};
        for(var i = 0; i < this.props.prices.parts.length; i++) {
            var part = this.props.prices.parts[i];
            if(jsonReply['generate_' + part.key] !== undefined)
                generate[part.key] = jsonReply['generate_' + part.key];
            else
                generate[part.key] = false;
        }

        var pcPoints = jsonReply['pointcloudPoints'];
        var meshTriang = jsonReply['meshTriangles'];
        var dists = jsonReply['distances'];
        var epsg = parseInt(jsonReply['epsg']);
        var zoomLevel = parseInt(jsonReply['zoomLevel']);
        var pointDef = parseInt(jsonReply['markerType']);
        var boundBox = (jsonReply['boundingBox'] != null) ? 
        {
            id:       jsonReply['boundingBox']['id'],
            width:    jsonReply['boundingBox']['width'],
            depth:    jsonReply['boundingBox']['depth'],
            height:   jsonReply['boundingBox']['height'],
            rotation: jsonReply['boundingBox']['roll'],
            lat:      parseFloat(jsonReply['boundingBox']['centerLatitude']),
            long:     parseFloat(jsonReply['boundingBox']['centerLongitude']),
        } : {id:0, width:0.0, depth:0.0, height:0.0, rotation:0.0, lat:0.0, long:0.0,};

        coords.forEach((item, i , arr) => {
            arr[i].point = parseInt(item.point);
            arr[i].lat = parseFloat(item.lat);
            arr[i].lng = parseFloat(item.lng);
            arr[i].alt = parseFloat(item.alt);
        });

        if ((this.state.imageCount      !== imgCnt)       ||
            (this.state.imagePosition   !== imgPos)       ||
            (this.state.bbCalculated    !== bbCalc)       ||
            (this.state.pcPoints        !== pcPoints)     ||
            (this.state.meshTriangles   !== meshTriang)   ||
            (this.state.pointDefinition !== pointDef)     ||
            (this.state.epsg            !== epsg)         ||
            (!_.isEqual(this.state.triangle,    triangle))||
            (!_.isEqual(this.state.generate,    generate))||
            (!_.isEqual(this.state.distances,   dists))   || 
            (!_.isEqual(this.state.coordinates, coords))  ||
            (!_.isEqual(this.state.bb,          boundBox))
        ){
            var epsgInfo = this.props.epsg.find(info => { return parseInt(info.epsg) === epsg });
            if(epsgInfo === undefined)   epsgInfo = this.state.epsgInfo;

            this.setState({     
                imageCount:     imgCnt,
                imagePosition:  imgPos,
                bbCalculated:   bbCalc,
                generate:       generate,
                pcPoints:       pcPoints,
                meshTriangles:  meshTriang,
                distances:      dists,
                coordinates:    coords,
                triangle:       triangle,
                pointDefinition:pointDef,
                bb:             boundBox,
                baseModel:      false,
                epsg:           epsg,
                epsgInfo:       epsgInfo,
                zoomLevel:      zoomLevel,
            });

            var wgsLambert = proj4("EPSG:" + epsg);
            this.toLambert = wgsLambert.forward;
            this.fromLambert = wgsLambert.inverse;
        }
    }

    /* When a part of the Boundingbox changes */
    handleMapClick(latlng){ this.handleBbChange({bb: {...this.state.bb, lat: latlng['lat'], long: latlng['lng']}}); }
    handleBbChange(state) { this.setState(state, this.handleBbStateChanged); }
    handleBbStateChanged() {
        this.StopPollBackend();
        this.sendDelayed(this.sendBbTimeout);
    }    
    sendChangeBoundingBox() {
        API.ChangeBoundingBox(this.state.id, this.state.bb);
        this.StartPollBackend();
    }

    /* When a part of the project changes */
    handleProjectChange(state){ this.setState(state, this.handleStateChanged); }
    handleStateChanged() {
        this.StopPollBackend();
        this.sendDelayed(this.sendTimeout);
    }
    sendChangeProject() {
        API.ChangeProject(this.state.id, this.state.generate, this.state.pcPoints, this.state.meshTriangles);
        this.StartPollBackend();
    }

    /* When the zoom level changes */
    handleZoomStateChanged() {  this.sendDelayed(this.sendZoomTimeout); }
    sendChangeZoom()         { API.ChangeZoom(this.state.id, this.state.zoomLevel); }

    /* When marker type changes */
    handleDistsCoordsChanged(value) {
        this.setState({pointDefinition: value});
        this.StopPollBackend();
        this.sendDelayed(this.sendDistsCoords);
    }
    sendChangeDistsCoords() {
        /*API.ChangeDistsCoords(this.state.id, (this.state.pointDefinition === PointDefinitions.Distance) ? false : true);*/
        API.ChangeMarkerType(this.state.id, this.state.pointDefinition);
        this.StartPollBackend();
    }

    /* When distance markers change */
    handleDistancesChanged(idx, p1, p2, d) {
        var distances = this.state.distances;
        if(p1 !== undefined) { distances[idx].point1 = p1; }
        if(p2 !== undefined) { distances[idx].point2 = p2; }
        if(d  !== undefined) { distances[idx].distance= d; }
        this.setState({distances: distances});

        this.StopPollBackend();
        this.sendDelayed(this.sendDistance);
    }
    handleDistanceDeleted(idx) {
        var dists = this.state.distances;
        var distance = dists[idx];
        dists.splice(idx ,1);
        this.setState({distances: dists});
        API.DeleteDistance(distance.id);
    }
    sendChangeDistance() {
        this.state.distances.forEach((distance) => { API.ChangeDistance(distance); });
        this.StartPollBackend();
    }
    sendCreateDistance(pId, distance) { API.AddDistance(pId, distance, this.handleCreateDistanceDone); }
    handleCreateDistanceDone(reply) {
        var dists = this.state.distances;
        dists.push(reply);
        this.setState({distances: dists});
    }

    /* When coordinate markers change */
    handleCoordinatesChanged(idx, p, coord, alt) {
        var coordinates = this.state.coordinates;
        if(p !== undefined)  coordinates[idx].point = p;
        if(coord !== undefined) { 
            coordinates[idx].lat = Math.round(coord[1] * 10000000) / 10000000;
            coordinates[idx].lng = Math.round(coord[0] * 10000000) / 10000000;
        }
        if(alt !== undefined) { 
            coordinates[idx].alt = Math.round(alt * 100) / 100;
        }
        this.setState({coordinates: coordinates});

        this.StopPollBackend();
        this.sendDelayed(this.sendCoordinate);
    }
    handleCoordinateDeleted(idx) {
        var coords = this.state.coordinates;
        var id = coords[idx].id;
        coords.splice(idx ,1);
        this.setState({coordinates: coords});
        API.DeleteCoordinate(id);
    }
    sendChangeCoordinate() {
        this.state.coordinates.forEach((coordinate) => { API.ChangeCoordinate(coordinate); });
        this.StartPollBackend();
    }
    sendCreateCoordinate(pId, coordinate) { API.AddCoordinate(pId, coordinate, this.handleCreateCoordinateDone); }
    handleCreateCoordinateDone(reply) {
        var coords = this.state.coordinates;
        var coord = {};
        coord.id = parseInt(reply.id);
        coord.point = parseInt(reply.point);
        coord.lat = parseFloat(reply.lat);
        coord.lng = parseFloat(reply.lng);
        coord.alt = parseFloat(reply.alt);
        coords.push(coord);
        this.setState({coordinates: coords});
    }

    handleTriangleChanged(triangle) {
        this.setState({triangle: triangle});

        this.StopPollBackend();
        this.sendDelayed(this.sendTriangle);
    }
    sendChangeTriangle() {
        API.ChangeTriangle(this.state.id, this.state.triangle);
        this.StartPollBackend();
    }

    /* Add a project to the run queue */
    handleAddProjectToQueue() { 
        if(this.props.uploadDone) {                 // If all images are uploaded, add project to queue
            API.AddProjectToRunList(this.state.id); 
        } else {                                    // Else wait until all images are uploaded
            this.setState({runWhenUpload: true});
        }
    }

    /* Get the marker ids */
    getPoints() {
        var list = [];
        for(let j = 0; j < 15; j++) { 
            list.push(<option key={j} value={j}>{"M " + j}</option>);
        }
        list.push(<option key={100} value={100}>{"M 100"}</option>);
        return list;
    }

    /* Load coordinates from a CSV */
    loadCoords(links) {
        links.forEach((link) => {
            var obj = {};
            obj.point = link.id;
            obj.lat   = Math.round(link.point[2] * 10000000) / 10000000;
            obj.lng   = Math.round(link.point[1] * 10000000) / 10000000;
            obj.alt   = Math.round(link.point[3] * 100) / 100;
            this.sendCreateCoordinate(this.state.id, obj);
        });
        this.setState({coordPopupOpen: false});
    }

    /* Select distance or coordinate markers */
    coordDist() {
        var ret = [];
        if(this.state.pointDefinition === PointDefinitions.Distance) {
            ret.push(<DistanceOptions 
                t={this.props.t}
                key="distOptions" 
                distances={this.state.distances} 
                points={this.getPoints()} 
                pointDefinition={this.state.pointDefinition} 
                onChange={(i, p1, p2, d) => this.handleDistancesChanged(i, p1, p2, d)} 
                onDelete={(i) => this.handleDistanceDeleted(i)}
                onCreate={() => this.sendCreateDistance(this.state.id, { point1: 0, point2: 0, distance: 0.0 })}
                onPointDefChanged={(e) => this.handleDistsCoordsChanged(parseInt(e.target.value))} />
            );
        } else if(this.state.pointDefinition === PointDefinitions.Coordinates) {
            ret.push(<CoordinateOptions 
                t={this.props.t}
                key="coordOptions"
                coordinates={this.state.coordinates}
                points={this.getPoints()}
                pointDefinition={this.state.pointDefinition} 
                popupOpen={this.state.coordPopupOpen}
                onChange={(i, p, coord, alt) => this.handleCoordinatesChanged(i, p, coord, alt)}
                onDelete={(i) => this.handleCoordinateDeleted(i)}
                onCreate={() => {
                    var coord = [0,0];
                    var obj = { point: 0, lat: Math.round(coord[1] * 10000000) / 10000000, lng: Math.round(coord[0] * 10000000) / 10000000, alt: 0 };
                    this.sendCreateCoordinate(this.state.id, obj);
                }}
                onFocusChanged={(i) => this.setState({selectedMarker: i})}
                onLoadCoords={this.loadCoords}
                onOpenCsv={() => this.setState({coordPopupOpen: true})}
                onPointDefChanged={(e) => this.handleDistsCoordsChanged(parseInt(e.target.value))}
                onClose={() => this.setState({coordPopupOpen: false})}
                epsg={this.state.epsgInfo}
            />);
        } else if(this.state.pointDefinition === PointDefinitions.Triangle) {
            ret.push(<TriangleOptions 
                t={this.props.t}
                key="triangleOptions"
                points={this.getPoints()}
                pointDefinition={this.state.pointDefinition} 
                triangle={this.state.triangle}
                onPointDefChanged={(e) => this.handleDistsCoordsChanged(parseInt(e.target.value))}
                onChange={(t) => this.handleTriangleChanged(t)}
            />);
        }
        return ret;
    }

    costTable() {
        var prices = this.props.prices.img;
        var prevMaxImg = 0;
        var tableRows = [<tr key='head'><th colSpan={5}>{this.props.t("pricesvary")}</th></tr>];
        prices.forEach(price => {
            tableRows.push(<tr key={price.maxImg}><td className='right'>{prevMaxImg}</td><td className='min'>-</td><td className='min'>{price.maxImg}</td><td className='min'> : </td><td>€{price.price}</td></tr>);
            prevMaxImg = price.maxImg;
        });
        return <table><tbody>{tableRows}</tbody></table>; 
    }

    getBasePrice() {
        var imgCount = this.props.imageCount;
        var prices = this.props.prices.img;
        var basePrice = parseFloat(this.props.prices.basePrice);
        var prevImgCount = -1;
        prices.forEach(price => { 
            var imgCntPrice = parseInt(price.maxImg);
            if(prevImgCount < imgCount && imgCount <= imgCntPrice) {
                basePrice = parseFloat(price.price);
            }
            prevImgCount = imgCntPrice;
        });

        return basePrice;
    }

    getBaseImages() {
        var imgCount = this.props.imageCount;
        var prices = this.props.prices.img;
        var images = "";
        
        var idx = prices.findIndex((price) => (imgCount < price.maxImg));
        if(idx === -1 || idx === 0) {
            images = "0 - " + prices[0].maxImg;
        } else {
            images = prices[idx - 1].maxImg + " - " + prices[idx].maxImg;
        }

        return images;
    }

    /* Calculate the run cost */
    calculateCost() {
        var cost = 0.0;
        if(this.state.baseModel) {
            //cost += parseFloat(this.props.prices.basePrice);
            cost += this.getBasePrice();
            for(const key in this.state.generate) {
                if(this.state.generate[key]) {
                    for(let i = 0; i < this.props.prices.parts.length; i++) {
                        if(key === this.props.prices.parts[i].key) {
                            if(this.props.prices.parts[i].price > 0)
                                cost += parseFloat(this.props.prices.parts[i].price);
                            break;
                        }
                    }
                }
            }
        }
        return cost;
    }

    render() {
        /* If no project is selected or the project has no images, don't show the config options */
        var ret = null;
        if(this.state.id !== 0 && this.state.imageCount !== 0 && this.state.bbCalculated === true) {
            var coords = {lat:this.state.bb.lat, lng:this.state.bb.long};
            var priceTable = this.costTable();

            ret = (
            <div key="options" className= {(this.props.tablet) ? 'options tablet' : ((this.props.phone) ? 'options phone' : 'options')}>
                {/* Show distance or coordinate marker options */}
                <div className='optionsTable optionsCoordDist'>
                    {(this.state.pointDefinition === PointDefinitions.Distance) ? <HelpPopup position="right top" gif={MeasureMarkers} btnClass="floatRight"/> : ""}
                    <table><tbody>
                        {this.coordDist()}
                    </tbody></table>
                </div>
                {/* Show boundingbox selection options */}
                <div className='optionsTable optionsSelection'>
                    <table width="100%"><tbody>
                        <tr>
                            <td><HelpPopup position={(this.props.tablet) ? "bottom left" : "top left"} gif={Selection} btnClass="floatLeft"/></td>
                            <th colSpan='3'>{this.props.t("selection")}</th>
                            <td>
                                <InfoSlide key="infoSlideCorner" text={this.props.t("selectioninfo")} />
                            </td>
                        </tr>
                    </tbody></table>
                    <table><tbody>
                        <tr>
                            <td>{this.props.t("width")}</td>
                            <td>
                                <input 
                                    type="number" 
                                    onChange={(e) => this.handleBbChange({bb: {...this.state.bb, width: e.target.value}})} 
                                    value={this.state.bb.width} 
                                    className='shortInput'
                                />
                            </td>
                            <td>m</td>
                            <td></td>
                        </tr><tr>
                            <td>{this.props.t("depth")}</td>
                            <td>
                                <input 
                                    type="number" 
                                    onChange={(e) => this.handleBbChange({bb: {...this.state.bb, depth: e.target.value}})} 
                                    value={this.state.bb.depth} 
                                    className='shortInput'
                                />
                            </td>
                            <td>m</td>
                            <td></td>
                        </tr><tr>
                            <td>{this.props.t("height")}</td>
                            <td>
                                <input 
                                    type="number" 
                                    onChange={(e) => this.handleBbChange({bb: {...this.state.bb, height: e.target.value}})} 
                                    value={this.state.bb.height} 
                                    className='shortInput'
                                />
                            </td>
                            <td>m</td>
                            <td></td>
                        </tr><tr>
                            <td>{this.props.t("rotation")}</td>
                            <td>
                                <input 
                                    type="range" 
                                    min="0" 
                                    max="1440" 
                                    onChange={(e) => this.handleBbChange({bb: {...this.state.bb, rotation: e.target.value / 4}})} 
                                    value={this.state.bb.rotation * 4} 
                                    className='shortInput'
                                />
                            </td>
                            <td>°</td>
                            <td></td>
                        </tr>
                    </tbody></table>
                </div>
                {/* Show detail options */}
                <div className='optionsTable optionsPoints'>
                    <table><tbody>
                        <tr><th colSpan='5'>{this.props.t("detail")}</th></tr>
                        <tr>
                            <td># {this.props.t("points")}</td>
                            <td><ThousandsInput key="pcPoints" onChange={(val) => this.handleProjectChange({pcPoints: val})} value={this.state.pcPoints} className='shortInput'/></td>
                        </tr>
                        <tr>
                            <td># {this.props.t("triangles")}</td>
                            <td><ThousandsInput key="meshTriang" onChange={(val) => this.handleProjectChange({meshTriangles: val})} value={this.state.meshTriangles} className='shortInput'/></td>
                        </tr>
                    </tbody></table>
                </div>
                {/* Show map with boundingbox */}
                <div className="boundingboxMap">
                {(this.state.imagePosition === "1" || this.state.coordinates.length > 0) ? 
                    <MapContainer 
                        key="map"
                        center={coords} 
                        zoom={this.state.zoomLevel} 
                        scrollWheelZoom={true} 
                        attributionControl={false}
                        className="mapContainer"
                    >
                        <ZoomChange onZoom={(lvl) => {this.setState({zoomLevel: lvl}, this.handleZoomStateChanged);}} />
                        <TileLayer url="https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}&s=Ga" maxZoom={20}/>
                        <BoundingBox
                            t={this.props.t}
                            onMove={this.handleMapClick}
                            startPosition={coords}
                            w={this.state.bb.width} d={this.state.bb.depth} r={this.state.bb.rotation}
                        />
                        {this.state.coordinates.map((coord, i) => {
                            var wgsCoord = this.fromLambert([coord.lng, coord.lat]);
                            return <Marker 
                                        key={"Marker_" + i} 
                                        position={{lat: wgsCoord[1], lng: wgsCoord[0]}}
                                        icon={(i === this.state.selectedMarker) ? SelectedIcon : DefaultIcon}
                                    >
                                        <Tooltip direction="bottom" offset={[0, 0]} opacity={1} permanent>{i.toString()}</Tooltip>
                                    </Marker>
                        })}
                    </MapContainer> : <></>}
                </div>
                {/* Show generation options */}
                <div className='optionsTable optionsGenerate'>
                    <table key="priceTable"><tbody>
                        <tr key='head'><th colSpan='3'>{this.props.t("generate")}</th><td><InfoSlide key="infoSlidePrices" text={priceTable} /></td></tr>
                        <tr key='basePrice'>
                            <th>{"€" + this.getBasePrice()}</th>
                            <td><input type="checkbox" onChange={(e) => this.setState({baseModel: e.target.checked})} checked={this.state.baseModel} /></td>
                            <td className='smallText'>{this.props.t("basemodel")} ({this.getBaseImages()} {this.props.t("images")})</td>
                        </tr>
                        {this.props.prices.parts.map((part) => {return (part.price <= 0) ? <></> : (
                        <tr key={part.key}>
                            <th>{"€" + parseFloat(part.price)}</th>
                            <td><input disabled={!this.state.baseModel} type="checkbox" onChange={(e) => this.handleProjectChange({generate: {...this.state.generate, [part.key]: e.target.checked}})} checked={this.state.generate[part.key]} /></td>
                            <td className='smallText'>{part.name}</td>
                        </tr>)})}
                    </tbody></table>
                </div>
                {/* Show total cost and start button */}
                <div className='optionsTable optionsStart center'>
                    <h1 className='cost'>{this.props.t("totalcost")}: <br/>€{this.calculateCost()}</h1>
                    <button 
                        disabled={(
                            !(
                                this.state.baseModel && 
                                this.props.prices.parts.reduce((prev, part) => {return (part.price > 0) ? (prev || this.state.generate[part.key]) : prev;}, false)
                            )
                        )} 
                        className='hugeButton generateButton' 
                        onClick={() => this.setState({confirmRun: true})}
                    >{this.props.t("generate")}</button>
                    <ConfirmPopup 
                        t={this.props.t}
                        open={(this.state.confirmRun === true)} 
                        title={this.props.t("startrun")} 
                        message={this.props.t("startrunconfirm") + ": €" + this.calculateCost()} 
                        onAccept={() => {
                            this.handleAddProjectToQueue()
                            this.setState({confirmRun: false, infoRun: true});
                        }}
                        onDecline={() => {
                            this.setState({confirmRun: false});
                        }}
                    />
                    <InfoPopup 
                        t={this.props.t}
                        open={(this.state.infoRun === true)} 
                        title={this.props.t("generationstarted")} 
                        message={this.props.t("generationstartedinfo")} 
                        onAccept={() => { this.setState({infoRun: false}); }}
                    />
                </div>
            </div>);
        }
        return ret;
    }
}

export default Project;