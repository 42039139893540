/***********************************************
* UploadPhotos.js
* 
* Handles the project view of the application
***********************************************/
/* Layout files */
import '../assets/style/UploadPhotos.css';

/* Global imports */
import React from 'react';

/* Local imports */
import API                          from './API';
import { ProgressPopup, InfoSlide, HelpPopup } from './Popup';

import uploadGif  from "../assets/images/UploadImages.gif";

/* Image row view */
const ImageItem = (props) => {
    return (
        <div key={"file-" + props.idx} className="fileRow">
            <div key="state" className="uploadState"><span className={"material-symbols-outlined " + props.className}>{props.icon}</span></div>
            <div title={props.name} key="name" className='uploadName'>{props.name}</div>
            <div key="btn" className='uploadBtn'>
                <button 
                    onClick={props.onDelete} 
                    className='uploadFileBtn'
                ><span className="material-symbols-outlined">close</span></button>
            </div>
        </div>
    );
}

class UploadPhotos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],          // List of files to upload
            progressFiles: [],  // List of files being uploaded
            doneFiles: [],      // List of files which are done uploading
            uploadedFiles: [],  // List of previously uploaded files
            project: this.props.project,
            uploading: false,
            progress: 0.0,
            open: false,
        };

        this.fileList = null;

        this.handleImageList   = this.handleImageList.bind(this);
        this.handleFileChange  = this.handleFileChange.bind(this);
        this.handleUploadClick = this.handleUploadClick.bind(this);
        this.handleUploadDone  = this.handleUploadDone.bind(this);
        this.handleUploadProgress = this.handleUploadProgress.bind(this);
    }

    /* If project changes, reload already uploaded images */
    componentDidUpdate(prevProps, prevState) {
        if(prevProps.project !== this.props.project) {
            this.setState({project: this.props.project, uploading: false})
            API.GetImages(this.props.project, this.handleImageList)
        }

        var newTotal = this.state.uploadedFiles.length + this.state.doneFiles.length + this.state.progressFiles.length + this.state.files.length;
        var oldTotal = prevState.uploadedFiles.length + prevState.doneFiles.length + prevState.progressFiles.length + prevState.files.length;
        if(newTotal !== oldTotal) {
            this.props.imageCount(newTotal);
        }
    }

    /* Handle the image list from the backend */
    handleImageList(reply) {
        const imgList = reply.map(r => ({...r, progress: 1.0}));
        this.setState({uploadedFiles: imgList});
        if(imgList.length > 0) {
            this.props.uploadDone(true);
        }
    }

    /* Load files on selection */
    handleFileChange(event) {
        this.fileList = event.target.files;
        this.setState({files: this.fileList ? [...this.fileList] : []});
        this.props.uploadDone(false);
    }

    /* Upload files when clicked on "upload" */
    handleUploadClick(event) {
        if(!this.fileList){ return; }

        this.setState({progress: 0.0})
        API.UploadFiles(this.state.project, this.state.files, this.state.progressFiles, this.state.doneFiles, this.handleUploadDone, this.handleUploadProgress);
    }

    /* When all files are uploaded, reload the image list */
    handleUploadDone() {
        API.GetImages(this.props.project, this.handleImageList);
        this.setState({progressFiles: [], doneFiles: []})
        this.props.uploadDone(true);
    }

    /* Handle the progress state */
    handleUploadProgress(totalProgress, fileProgress) {
        this.setState({progress: totalProgress});
        //this.setState({open: (progress > 0.0 && progress < 1.0), progress: progress});
    }

    render() {
        var uploaded = this.state.uploadedFiles.length + this.state.doneFiles.length;
        var total    = this.state.uploadedFiles.length + this.state.doneFiles.length + this.state.progressFiles.length + this.state.files.length;
        return (<>
            {/* Show amount of uploaded images */}
            <div className="uploadHeader">
                {uploaded} / {total} {this.props.t("imagesuploaded")}
                <HelpPopup position="top right" gif={uploadGif} btnClass="floatRight"/>
            </div>
            {/* Show list of all files */}
            <div className="uploadList">
                {this.state.uploadedFiles.map((file, i) => {
                    return <ImageItem key={"upload-" + i} idx={"upload-" + i} name={file.imageName} onDelete={() => {}} icon="check_circle" className="uploadStateDone"/>
                })}
                {this.state.doneFiles.map((file, i) => {
                    return <ImageItem key={"done-" + i} idx={"done-" + i} name={file.name} onDelete={() => {}} icon="check_circle" className="uploadStateDone"/>
                })}
                {this.state.progressFiles.map((file, i) => {
                    return <ImageItem key={"progress-" + i} idx={"progress-" + i} name={file.name} onDelete={() => {}} icon="change_circle" className="uploadStateRunning"/>
                })}
                {this.state.files.map((file, i) => {
                    return <ImageItem key={"files-" + i} idx={"files-" + i} name={file.name} onDelete={() => {
                        var tmp = this.state.files;
                        tmp.splice(i, 1);
                        this.setState({files: tmp});
                    }} icon="upload" className=""/>
                })}
            </div>
            {/* Show upload buttons */}
            <div className="uploadAdd">
                <InfoSlide text={this.props.t("imageinfo")} position="top center"/>
                <label htmlFor="files" className='bigButton'>{this.props.t("imagesadd")}</label>
                <input id='files' type="file" onChange={this.handleFileChange} multiple style={{display: "none"}} accept="image/png, image/jpeg"/>
                <button disabled={(this.state.files.length <= 0) || (this.state.project <= 0)} className='bigButton' onClick={this.handleUploadClick}>{this.props.t("upload")}</button>
                {(this.state.files.length > 0) ? <span>{this.state.files.length} {this.props.t("selected")}</span> : <></>}
            </div>
            <ProgressPopup open={this.state.open} progress={Math.round(this.state.progress * 100)} title={this.props.t("uploading")} />
        </>
        );
    }
}

export default UploadPhotos;

