/***********************************************
* Popup.js
* 
* Handles all the popup views
***********************************************/
/* Layout files */
import 'reactjs-popup/dist/index.css';
import '../assets/style/Popup.css';

/* Global imports */
import React, { useEffect, useState } from 'react';
import Popup               from 'reactjs-popup';
import Papa                from "papaparse";

/* A progressbar popup */
export function ProgressPopup(props) {
    const [open,     setOpen]     = useState(false);
    const [progress, setProgress] = useState(0.0);
    const [title,     setTitle]     = useState("");

    if(props.open !== open)         setOpen(props.open);
    if(props.progress !== progress) setProgress(props.progress);
    if(props.title !== title)       setTitle(props.title);

    return (
    <Popup className="mediumPopup" open={open} key="popup">
        <div>
            <h2>{title}</h2>
            <p>{progress}%</p>
            <div className='progressBar'>
                <div className='progress' style={{width: progress + '%'}}></div>
            </div>
        </div>
    </Popup>
    );
}

/* A coordinate import from CSV popup */
export class CoordinatesPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            markers: [],
            links: [],
        };

        this.buildPopup = this.buildPopup.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.markers !== this.props.markers) {
            this.setState({markers: this.props.markers});
        }
        if(prevProps.open !== this.props.open) {
            this.setState({open: this.props.open});
        }
    }

    buildPopup() {
        var ret = []
        ret.push(<h2 key="title">{this.props.t("loadfromcsv")}</h2>);
        ret.push(<p key="info">{this.props.t("csvinfo1")}<br />{this.props.t("csvinfo2")} </p>);
        ret.push(<pre key="csvFormat">{this.props.t("csvorder")}</pre>);
        ret.push(<label key="csvLabel" htmlFor="csvFile" className='bigButton'>{this.props.t("selectcsv")}</label>);
        ret.push(<input key="csvInput" id='csvFile' type="file" name="file" accept=".csv" style={{ display: "none" }} 
            onChange={(event) => {
                Papa.parse(event.target.files[0], { header: false, skipEmptyLines: true,
                    complete: (results) => {
                        var tmp = [];
                        results.data.forEach((item, i) => { tmp.push({id: i, point: item}); });
                        this.setState({links: tmp});
                    },
                });
            }}
        />);
        ret.push(<table key='coordTable'><tbody>
        <tr><th>{this.props.t("csvname")}</th><th>{this.props.t("markerid")}</th></tr>
        {this.state.links.map((link, i) => { 
            var name = link.point[0];
            var nameId = parseInt(name.match(/\d+/)[0]);
            nameId = !isNaN(nameId) ? nameId : link.id;
            return (<tr key={name}><td>{name}</td><td><select name="markerPoint" onChange={(e) => {
                var tmp = this.state.links;
                tmp[i].id = parseInt(e.target.value);
                this.setState({links: tmp});
            }} value={nameId}>{ this.state.markers }</select></td></tr>);
        }) }
        <tr key='btn'><td colSpan={2} className='center'><button className='bigButton' onClick={() => { this.props.onLoadCoords(this.state.links) }}>{this.props.t("savecoords")}</button></td></tr>
        </tbody></table>);
        return ret;
    }

    render() {
        return (
        <Popup className="mediumPopup" open={this.state.open} onClose={() => { this.setState({open: false}); this.props.onClose();}}>
            <div>{ this.buildPopup() }</div>
        </Popup>
        );
    }
}

/* A create new project popup */
export function ProjectPopup(props) {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState(props.text);
    const [prevName, setPrevName] = useState(props.text);

    if(props.open !== open) setOpen(props.open);
    if(props.text !== prevName) {
        setPrevName(props.text);
        setName(props.text);
    }

    const handleEnter = (event) => {
        if(event.key === "Enter"){ 
            props.onCreate(name);
            setName("");
        }
    }

    return (
    <Popup className="smallPopup" open={open} onClose={() => {setOpen(false); props.onClose();}}>
        <div>
            <h2>{props.title}</h2>
            <input 
                name="ProjectName" 
                autoFocus 
                value={name} 
                onChange={(event) => setName(event.target.value)} 
                onKeyDown={handleEnter}/><br/><br/>
            <button className="bigButton" onClick={() => {props.onSave(name); setName("");}}>{props.btnText}</button>
        </div>
    </Popup>
    );
}

/* A confirmation popup */
export function ConfirmPopup(props) {
    const [open,  setOpen]  = useState(false);
    const [title, setTitle] = useState("");
    const [msg,   setMsg]   = useState("");

    if(props.open !== open)   setOpen(props.open);
    if(props.title !== title) setTitle(props.title);
    if(props.message !== msg) setMsg(props.message);

    return (
    <Popup className="smallPopup" open={open} onClose={() => {setOpen(false); props.onDecline();}}>
        <div>
            <h2>{title}</h2>
            <p>{msg}</p>
            <button className="bigButton" onClick={() => props.onAccept()}>{props.t("accept")}</button>
            <button className="bigButton" onClick={() => props.onDecline()}>{props.t("cancel")}</button>
        </div>
    </Popup>
    );
}

/* An information popup */
export function InfoPopup(props) {
    const [open,  setOpen]  = useState(false);
    const [title, setTitle] = useState("");
    const [msg,   setMsg]   = useState("");

    if(props.open !== open)   setOpen(props.open);
    if(props.title !== title) setTitle(props.title);
    if(props.message !== msg) setMsg(props.message);

    return (
    <Popup className="smallPopup" open={open} onClose={() => {setOpen(false); props.onAccept();}}>
        <div>
            <h2>{title}</h2>
            <p>{msg}</p>
            <button className="bigButton" onClick={() => props.onAccept()}>{props.t("ok")}</button>
        </div>
    </Popup>
    );
}

/* A hover information popup */
export function InfoSlide(props) {
    var position = (props.position !== undefined) ? props.position : "right center"
    return (<Popup
        trigger={() => (<div className='info'></div>)}
        position={position}
        on={['hover', 'focus']}
        closeOnDocumentClick
        className='infoSlide'
    >
        <span>{props.text}</span>
    </Popup>);
}


export function HelpPopup(props) {
    const [imgPath, setImg]   = useState("");
    const [tmpImg, setTmpImg] = useState("");

    if(props.gif !== imgPath)   setImg(props.gif);

    useEffect(() => { 
        if(tmpImg !== imgPath) { 
            setTmpImg(imgPath); 
        }
    }, [tmpImg, imgPath]);

    return (<Popup
        trigger={<button className={"iconButton helpButton " + props.btnClass}><span className="material-symbols-outlined">question_mark</span></button>}
        position={props.position}
        on="hover"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        arrow={false}
        className={props.className + " gifPopup"}
        onOpen  = { () => setTmpImg("") }
    >
        <img src={tmpImg} alt="Help Gif"/>
    </Popup>);
}