import i18n from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n.use(Backend)
 .use(initReactI18next)
 .init({
  backend: { loadPath: "/translations/{{ns}}/{{lng}}.json", },
  fallbackLng: "en",
  debug: false,
  ns: ["Cookie", "Login", "Misc", "Projects", "UserInfo"],
  interpolation: {
   espaceValue: false,
   formatSeparator: ",",
  },
  react: { useSuspense: true, },
  });
export default i18n;