/***********************************************
* Privacy.js
* 
* Show the Privacy Policy
***********************************************/
/* Layout files */
import '../assets/style/RenderMarkdown.css';

/* Global imports */
import React                                   from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Markdown                                from 'markdown-to-jsx'
import { withTranslation }                     from 'react-i18next';

/* Allow this component to navigate to different pages */
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
        let location = useLocation();
        let navigate = useNavigate();
        let params = useParams();
        return (<Component {...props} router={{ location, navigate, params }} />);
    }
  
    return ComponentWithRouterProp;
}

class RenderMarkdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = { markdown: '' };

        fetch(props.markdownFile).then(res => res.text()).then(text => this.setState({ markdown: text }));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.language !== this.props.language) {
            fetch(this.props.markdownFile).then(res => res.text()).then(text => this.setState({ markdown: text }));
        }
    }

    render() {
        const navigate = this.props.router.navigate;
        const { markdown } = this.state;

        return (
            <div className="fullLandingPage">
                <div className="loginLandingDiv">
                    <select name="language" id="language" className="languageSelect" value={this.props.language.active} onChange={(e) => this.props.language.set(e.target.value)}>
                        {this.props.language.list.map((lang) => <option key={lang} value={lang}>{lang}</option>)}
                    </select>
                    <div onClick={() => navigate("/")} className="button bigButton">{this.props.t("home")}</div>
                </div>
                <div className='contentPageMd'>
                    <Markdown>{markdown}</Markdown>
                </div>
            </div>
        );
    }
}

export default withTranslation("Misc")(withRouter(RenderMarkdown));