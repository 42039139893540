/***********************************************
* SideBar.js
* 
* Show the project list
***********************************************/
/* Layout files */
import '../assets/style/SideBar.css'

/* Global imports */
import React   from 'react';

/* Local imports */
import API              from './API';
import NewOpenProject   from './NewOpenProject';
import { ConfirmPopup, HelpPopup } from "./Popup";

import newProjGif  from "../assets/images/NewProject.gif";

export default class SideBar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            projectId: 0,           // Selected project ID
            projectList: [],        // The list of projects
            deleteProject: -1,      // Project ID to delete
            deleteTitle: "",        // Project name to delete
            expandSidebar: false,   // Show or hide sidebar
        };

        this.handleProjectsLoaded = this.handleProjectsLoaded.bind(this);
        this.handleProjectRemoved = this.handleProjectRemoved.bind(this);
        this.handleCreatedNew     = this.handleCreatedNew.bind(this);
    }

    /* Poll the project list every 10 seconds */
    componentDidMount() {
        API.GetProject(-1, this.handleProjectsLoaded);
        this.apiTimer = setInterval(() => API.GetProject(-1, this.handleProjectsLoaded), 10000);
    }
    componentWillUnmount() { clearInterval(this.apiTimer); }
    
    /* Parse project list from reply */
    handleProjectsLoaded(reply) {
        var projectList = [];
        reply.forEach((json) => projectList.push(json));
        this.setState({projectList: projectList});
    }

    /* Reload the project list when a project is deleted */
    handleProjectRemoved(reply) { API.GetProject(-1, this.handleProjectsLoaded); }

    /* Select newly created project */
    handleCreatedNew(reply) {
        var projectList = this.state.projectList;
        projectList.unshift(reply);
        this.setState({projectList: projectList, projectName: reply["title"], projectId: reply["id"]});
        this.props.onProjectChanged(reply["id"], reply["title"]);
    }

    /* Get project state icon */
    getProjectState(project) {
        if(project.complete === true) {
            return <span className="material-symbols-outlined sbStateDone">check_circle</span>;
        } else if(project.running === true) {
            return <span className="material-symbols-outlined sbStateRunning">change_circle</span>;
        } else {
            return "";
        }
    }

    render() {
        var projectList = this.state.projectList;
        return (
            <div className={(this.props.open) ? ((this.state.expandSidebar) ? 'sidebar sbExpand' : 'sidebar') : "sidebar sbClosed"} key="sidebar">
                {/* Button to create a new project */}
                <NewOpenProject t={this.props.t} onSave={(name) => API.CreateProject(name, this.handleCreatedNew)} key="newProj"/>
                <HelpPopup position="right top" gif={newProjGif} />
                {/* Button to expand the sidebar for longer text */}
                <button className={(this.state.expandSidebar) ? "sbRotateBtn rotatedBtn" : "sbRotateBtn"} onClick={() => this.setState({expandSidebar: !this.state.expandSidebar})}>
                    <span className="material-symbols-outlined">navigate_next</span>
                </button>
                {/* The list of projects */}
                <div key="projectList" className='sbTable'>
                    {projectList.map(
                        (project, i) => {
                            return (<div key={"row" + i} className={(project.id === this.state.projectId) ? 'topRow activeProject' : 'topRow'} onClick={() => {
                                this.setState({projectName: project.title, projectId: project.id});
                                this.props.onProjectChanged(project.id, project.title);
                            }}>
                                <div key="state" className="sbState">{ this.getProjectState(project) }</div>
                                <div title={project.title} key="title" className='sbTitle'>{project.title}</div>
                                <div key="btn" className='sbBtn'>
                                    <button onClick={() => {
                                        this.setState({deleteProject: i, deleteTitle: project.title});
                                    }} className='sbProjBtn'><span className="material-symbols-outlined">close</span></button>
                                </div>
                            </div>
                            );
                        }
                    )}
                </div>

                <a className='button hugeButton smallerText' href="https://photomation.be/Markers.pdf" download="Markers.pdf" target="_blank" rel="noopener noreferrer">{this.props.t("downloadmarkers")}</a>

                {/* Delete confirm popup */}
                <ConfirmPopup 
                    t={this.props.t}
                    open={(this.state.deleteProject >= 0)} 
                    title={this.props.t("delete") + " " + this.state.deleteTitle} 
                    message={this.props.t("suredelete") + " " + this.state.deleteTitle} 
                    onAccept={() => {
                        if(projectList) {
                            var id = projectList[this.state.deleteProject].id;
                            if(id === this.state.projectId){
                                this.setState({projectId: 0, projectName: ""});
                                this.props.onProjectChanged(0, "");
                            } 
                            projectList.splice(this.state.deleteProject, 1);
                            API.RemoveProject(id, this.handleProjectRemoved);
                            this.setState({deleteProject: -1, deleteTitle: ""});
                        }
                    }} 
                    onDecline={() => {
                        this.setState({deleteProject: -1, deleteTitle: ""});
                    }}
                />
            </div>
        );
    }
}